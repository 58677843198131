
import { ElForm } from 'node_modules/element-ui/types/form';
import { Component, Vue, Prop, Watch, Emit  } from 'vue-property-decorator';

@Component({
    name: "PerfectInfo",
})
export default class PerfectInfo extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    }) private exitFlag!: boolean; // 接收父组件传过来的值
    @Prop(String) private title!: string;
    private showDialog: boolean = false; // 弹窗显示与关闭
    private formTab = {
        name: '',
        position: '',
        company: '',
    };
    private caption: string = "完善信息";
    private rules: any = {
        name: [
            { required: true, message: '请输入您的姓名', trigger: 'blur' },
        ],
        position: [
            { required: true, message: '请输入您的职务', trigger: 'blur' },
        ],
        company: [
            { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
    };
    @Watch('exitFlag') // 监听父组件传过来的值
    private changeFlagFun(value: boolean) {
        this.showDialog = value;
        if (value) {
            this.getUserInfo();
        }
    }
    @Watch('title') // 监听父组件传过来的值
    private titleFun(value: string) {
        this.caption = value;
    }
    // 给父组件传值
    @Emit('closeMsg')
    private closeTodo(num: number): number {
        return num;
    }
    // 获得用户信息
    private getUserInfo() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/users/contacts').then((res: any) => {
            this.formTab = {
                name: res.name,
                position: res.position,
                company: res.company,
            };
        });
    }
    //  确定按钮
    private submit(submitName: string) {
        const elForm = this.$refs[submitName]! as ElForm;
        elForm.validate((valid) => {
            if (valid) {
                this.$httpService.putData(this.formTab, '/apiProxy/api/frontend/users/contacts')
                .then((res: any) => {
                    this.showDialog = false;
                    this.closeTodo(3); // 给父组件传值
                    this.$message({
                        message: '提交成功！',
                        type: 'success',
                    });
                    // window.open(res);
                });
            }
        });
    }
    private closeDialog(num: number) {
        this.showDialog = false;
        this.closeTodo(num); // 给父组件传值
    }
    // 弹窗关闭后清除所有数据
    private afterClose() {
        const elForm = this.$refs.formRef! as ElForm;
        elForm.resetFields();
    }
    // 右上角的关闭按钮
    private beforeclose() {
        this.showDialog = false;
        this.closeTodo(1); // 给父组件传值
    }
}
