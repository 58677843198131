
import { Component, Vue } from 'vue-property-decorator';
import SingleHeader from '@/components/singleHeader.vue';
import SingleFooter from '@/components/singleFooter.vue';
import PerfectDialog from "@/components/RetainInfoDialog/PerfectDialog.vue";

@Component({
    name: "ExperienceCenter",
    components: {
        SingleHeader,
        SingleFooter,
        PerfectDialog,
    },
})
export default class ExperienceCenter extends Vue {
    private anchorIndex: number = 0;
    private perfectVisible: boolean = false;    // 体验前完善信息弹窗
    private experienceItem: any = {};   // 当前体验产品
    private token: any = null; // 上报时需要记录的token
    // vue的mounted方法中, 写设置页面滚动条的这个方法
    private mounted() {
        this.$nextTick(() => {
            if (this.$route.fullPath.indexOf('anchor=') > -1) {
                this.anchorIndex = Number(this.$route.query.anchor + '');
                this.goAnchor(this.anchorIndex);
            } else {
                setTimeout(() => {
                    this.handleAnchorScroll();
                }, 200);
                // 切换页面时滚动条自动滚动到顶部
                window.scrollTo(0, 0);
            }
        });
        window.addEventListener("scroll", this.handleAnchorScroll, true);
    }
    private destroyed() {
        window.removeEventListener('scroll', this.handleAnchorScroll, true);
    }
    private handleAnchorScroll() {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        const anchor1OffsetTop = document.getElementById(`anchor1`)!.offsetTop;
        const anchor2OffsetTop = document.getElementById(`anchor2`)!.offsetTop;
        const anchor3OffsetTop = document.getElementById(`anchor3`)!.offsetTop;
        const anchor4OffsetTop = document.getElementById(`anchor4`)!.offsetTop;
        const anchor5OffsetTop = document.getElementById(`anchor5`)!.offsetTop;
        if (scrollTop < anchor1OffsetTop) {
            this.anchorIndex = 1;
        } else if (scrollTop >= anchor1OffsetTop + 100 && scrollTop < anchor2OffsetTop + 100) {
            this.anchorIndex = 2;
        } else if (scrollTop >= anchor2OffsetTop + 100 && scrollTop < anchor3OffsetTop + 100) {
            this.anchorIndex = 3;
        } else if (scrollTop >= anchor3OffsetTop + 100 && scrollTop < anchor4OffsetTop + 50) {
            this.anchorIndex = 4;
        } else if (scrollTop >= anchor4OffsetTop + 50) {
            this.anchorIndex = 5;
        }
    }
    private goAnchor(anchorNum: number) {
        document.getElementById(`anchor${anchorNum}`)!.scrollIntoView({
            behavior: "smooth",
        });
        this.anchorIndex = anchorNum;
    }
    private async clickHandle(handleType: number) {
        if (handleType === 3) {
            this.$router.push('/sampleCenter');
            return;
        }
        if (!await this.$isLogin()) {
            this.loginTips();
            return;
        }
        // 1,制造协同 立即探索
        // 2,Wis3D 创建
        // 3,wis3D 样例
        // 4,WisApp 立即探索
        // 5,设备管理 立即探索
        // 6,标签 立即探索
        if (handleType === 1) {
            this.experienceNow(112);
        } else if (handleType === 2) {
            this.experienceNow('wis3d');
        } else if (handleType === 4) {
            this.experienceNow('wisApp');
        } else if (handleType === 5) {
            this.experienceNow(134);
        } else if (handleType === 6) {
            this.experienceNow(110);
        }
    }
    private loginTips() {
        this.$confirm('请登录后操作', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
        }).then(() => {
            this.$router
            .push({
                path: '/login',
                query: {
                    redirect: `${window.location.origin}${window.location.pathname}?anchor=${this.anchorIndex}`,
                },
            }).catch((err) => err);
        }).catch(() => {
            // ...
        });
    }
    // 获得体验产品信息
    private goToLearn(productId: any) {
        const paramData: any = {};
        paramData.productId = productId;
        this.$httpService.getData(paramData, `/apiProxy/api/frontend/experience/entrance`)
        .then((res: any) => {
            if (productId === 'wis3d' || productId === 'wisApp') {
                this.entranceReport(productId);
            }
            window.open(res);
        });
    }
    // wis3d和wisApp体验前先验证
    private entranceVerification(productId: any) {
        const paramData: any = {};
        paramData.productId = productId;
        this.$httpService.getData(paramData, `/apiProxy/api/frontend/experience/entrance/limit/verification`)
        .then((res: any) => {
            this.token = res.token;
            if (res.status === 3) {
                this.expireDialog(res.productName);
            } else if (res.status === 1) {
                this.startDialog(res.productName, res.remain, productId);
            } else {
                this.goToLearn(productId);
            }
        });
    }
    // 上报
    private entranceReport(productId: any) {
        const paramData: any = {};
        paramData.productId = productId;
        paramData.token = this.token;
        this.$httpService.postData(paramData, `/apiProxy/api/frontend/experience/entrance/report`)
        .then((res: any) => {
            // 上报成功
        });
    }
    // 立即体验 开始留资检查
    private experienceNow(productId: any) {
        this.$httpService.postData({}, `/apiProxy/api/frontend/experience/precheck`)
        .then((res: any) => {
            if (productId === 'wis3d' || productId === 'wisApp') {
                this.entranceVerification(productId);
            } else {
                this.goToLearn(productId);
            }
        }).catch((err: any) => {
            if (err.code === 1004004108) {
                this.experienceItem.productId = productId;
                this.perfectVisible = true;
            }
        });
    }
    private closeExperienceNow(num: number) {
        this.perfectVisible = false;
        if (num === 3) {
            this.experienceNow(this.experienceItem.productId);
        }
    }
    // 过期弹框
    private expireDialog(productName: any) {
        const msg = `您的<span style="color:#1890FF;">${productName}</span>体验已过期，如需继续使用，请联系平台运营人员！`;
        this.$alert(msg, '体验过期', {
            confirmButtonText: '我知道了',
            customClass: 'experienceCenter_customClass',
            showClose: false,
            dangerouslyUseHTMLString: true,
            callback: (action) => {
                // ...
            },
        });
    }
    // 开始弹框
    private startDialog(productName: any, remain: any, productId: any) {
        const msg =
            `您的<span style="color:#1890FF;">${productName}</span>体验时间为<span style="color:#FF0000;">${remain}</span>天，是否立即开始体验？`;
        this.$confirm(msg, '开始体验', {
            confirmButtonText: '开始体验',
            cancelButtonText: '暂不体验',
            customClass: 'experienceCenter_customClass',
            dangerouslyUseHTMLString: true,
            closeOnClickModal: false,
            showClose: false,
        }).then(() => {
            // 确定
            this.goToLearn(productId);
        });
    }
}
